import { useEffect, useState } from "react";
import api from "../services/api";
import useTracking from "../services/useTracking";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function BlogList() {
  const [posts, setPosts] = useState([]);
  const tracking = useTracking("Blog List");

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPosts = async () => {
    const res = await api.getBlogPosts();
    setPosts(res.data);
  };

  return (
    <>
      <Helmet>
        <title>{"Create Blog AI | Blog "}</title>
      </Helmet>

      <Navbar></Navbar>
      <div className="blog-list-container">
        <h1>Blog</h1>

        {posts.map((post, i) => (
          <div className="blog-container" key={i}>
            <Link
              to={"/blog/" + post._id + "/" + post.urlPost}
              className="blog-name"
              onClick={() => tracking("Click blog post link", "Blog Post " + post._id)}
            >
              <h3>{post.title}</h3>
            </Link>
            <div className="blog-description">{post.metaDescription}</div>
          </div>
        ))}
      </div>
      <Footer></Footer>
    </>
  );
}
