import React, { useState, useContext, createContext } from "react";

// APIs
import api from "../services/api";

export const authContext = createContext();
const userId = crypto.randomUUID();

function useAuthProvider() {
  const [token, setToken] = useState();
  const [user, setUser] = useState();

  const login = async (user, pwd) => {
    let result = await api.login(user, pwd);

    if (result.data.token) {
      localStorage.setItem("user_token", result.data.token);
      checkAuth();
      // window.location = "/admin";
    }
  };

  const successLoginGoogle = async (credentials) => {
    let res = await api.registerGoogle(credentials.credential);
    if (res.status === 200) {
      register(res.data.token, res.data.email);
    }
  };

  const register = async (token, email) => {
    if (window.smartlook) {
      window.smartlook("track", "registration_complete");
      window.smartlook("identify", email);
    }

    window.gtag("set", { user_id: email });

    window.gtag("event", "registration_complete", {
      event_callback: function () {
        window.location.href = process.env.REACT_APP_APP_URL + "?token=" + token;
      },
    });
  };

  const logout = async () => {
    localStorage.removeItem("user_token");
    setToken(null);
    setUser(null);
    // window.location = "/login";
  };

  const checkAuth = async () => {
    let token = localStorage.getItem("user_token");
    if (token) {
      setToken(token);
      api.setToken(token);

      const response = await api.getUser();

      if (response.status === 200 && response.data) {
        setUser(response.data);
      } else {
        // if (window.location.pathname !== "/login") window.location = "/login";
      }
    } else {
      // if (window.location.pathname !== "/login") window.location = "/login";
    }
  };

  return {
    token,
    user,
    setToken,
    checkAuth,
    logout,
    login,
    successLoginGoogle,
    register,
    userId,
  };
}

export function ProvideAuth({ children }) {
  const values = useAuthProvider();
  return <authContext.Provider value={values}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
