import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category = "createblogaiTracking") => {
  const eventTracker = (action, label, value) => {
    ReactGA.event({ category, action, label, value });
    if (window.smartlook) window.smartlook("track", action, { category: category, action: action, value: value });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
