import React from "react";
import Routes from "./Routes";
import ReactGA from "react-ga4";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Context
import { ProvideAuth } from "./context/useAuth";
import { BrowserRouter } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function App() {
  return (
    <div className="main-container">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ProvideAuth>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ProvideAuth>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
