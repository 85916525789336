import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useGoogleOneTapLogin } from "@react-oauth/google";

// Pages
import Home from "./pages/Home";
import BlogList from "./pages/BlogList";
import BlogPost from "./pages/BlogPost";

import ReactGA from "react-ga4";
import Terms from "./pages/Terms";

// Context
import { useAuth } from "./context/useAuth";

export default function RoutesApp() {
  let auth = useAuth();
  const location = useLocation();

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      auth.successLoginGoogle(credentialResponse);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send("pageview");
  }, [location.pathname]);

  useEffect(() => {
    auth.checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/terms" element={<Terms />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:id/:desc" element={<BlogPost />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
