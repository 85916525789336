import { useEffect, useState } from "react";
import api from "../services/api";
import useTracking from "../services/useTracking";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import { Helmet } from "react-helmet";
import RegisterModal from "../components/RegisterModal";

export default function BlogPost() {
  const [post, setPost] = useState({});
  const [showModalRegister, setShowModalRegister] = useState(false);
  const tracking = useTracking("Blog view");
  let { id } = useParams();

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchPost = async () => {
    const res = await api.getBlog(id);
    setPost(res.data);
  };

  return (
    <>
      <Helmet>
        <title>{"Create Blog AI | Blog | " + post.title}</title>
        <meta name="keywords" content={post.keywords && post.keywords.join(",")} />
        <meta name="description" content={post.metaDescription} />
      </Helmet>

      <Navbar></Navbar>
      <div className="blog-post-page">
        <div style={{ marginBottom: "25px" }}>
          <Link to={"/blog"} className="back-link" onClick={() => tracking("Click back to all prods")}>
            Back to all blog posts
          </Link>
        </div>

        <h1>{post.title}</h1>

        <div className="blog-post-container">
          {post.image && (
            <div className="blog-post-image-conatiner">
              <img className="blog-post-image" src={post.image} alt={post.name} />
            </div>
          )}
          <div className="blog-post-long-description">
            <MDEditor.Markdown linkTarget="_blank" source={post.content} style={{ whiteSpace: "pre-wrap" }} />
          </div>
        </div>

        <div className="blog-post-footer">
          <p>
            Experience the future of content creation! This article has been expertly written by our AI-powered tool, showcasing
            the seamless capabilities of AI in generating high-quality and engaging blog posts. Imagine having this powerful AI as
            your personal blogging assistant, creating captivating content on autopilot. Unlock the potential of effortless
            content creation and take your blog to new heights.
          </p>
          <p>
            Don't miss out on this revolutionary opportunity – register now and let AI be the pen behind your blog! Click the
            button below to join our Early Access Program.
          </p>
          <div className="blog-cta">
            <button
              className="big"
              onClick={() => {
                tracking("Open register modal from Blog");
                setShowModalRegister(true);
              }}
            >
              GET EARLY ACCESS
            </button>
          </div>
        </div>
      </div>

      {showModalRegister && <RegisterModal close={() => setShowModalRegister(false)}></RegisterModal>}
      <Footer></Footer>
    </>
  );
}
