import React, { useLayoutEffect, useState } from "react";
import useTracking from "../services/useTracking";

// Img
// import logo from "../images/Logo text Horizontal White .svg";
import header from "../images/header.webp";
import pencil from "../images/pencils.webp";
import sculpture from "../images/sculpture.webp";
import drawing from "../images/drawing.webp";
import painting from "../images/painting.webp";
import sculpting from "../images/sculpting.webp";

// Components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RegisterModal from "../components/RegisterModal";

// Libs
import { Helmet } from "react-helmet";

export default function Home() {
  const tracking = useTracking("Home");
  const [scrollPosition, setPosition] = useState(0);
  const [showModalRegister, setShowModalRegister] = useState(false);

  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  const openModal = (track) => {
    tracking(track);
    setShowModalRegister(true);
  };

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://createblog.ai/" />
        <meta property="og:title" content="Create Blog AI " />
        <meta property="og:site_name" content="createblogai" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="." />
        <meta property="og:image" content="https://createblog.ai/logo.svg" />
        <meta property="og:image:alt" content="Create Blog AI" />
        <meta property="twitter:url" content="https://createblog.ai/" />
        <meta property="twitter:title" content="Create Blog AI" />
        <meta property="twitter:description" content="." />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image:src" content="https://createblog.ai/logo.png" />
      </Helmet>
      {/* <Header></Header> */}
      <div className="main-content waiting-list-container">
        {/* HEADER */}
        <Navbar transparent={scrollPosition < 50}></Navbar>
        <div className="header-box">
          {/* <img className="header-logo" src={logo} alt="Logo"></img> */}

          <div className="header-content">
            <div className="header-left">
              <div style={{ textAlign: "center", marginTop: 50, border: "1x solid black" }}>
                <video style={{ width: "100%", maxWidth: 200 }} autoPlay controls src="./demo.mp4"></video>
              </div>
              {/* <img className="header-img" src={header} alt="doctor"></img> */}
            </div>
            <div className="header-right">
              <h1>Unleash the Power of AI: Your Autonomous Blogging Companion</h1>

              {/* <div style={{ textAlign: "center", marginTop: 50, border: "1x solid black" }}>
                <video style={{ width: "100%", maxWidth: 200 }} autoPlay controls src="./demo.mp4"></video>
              </div> */}
              <h3>Effortless Content Creation and Scheduled Publishing with API and Wordpress Integration</h3>

              <div className="btn-content">
                <button onClick={() => openModal("Open register modal from header")} className="primary big">
                  Get Started Free
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 1 */}
      <div className="section section-1">
        <div className="section-left">
          <h1>AI-Powered Content Creation</h1>
          <h3>
            Welcome to the future of blogging!<br></br>
            Our AI tool generates engaging and high-quality blog posts from a single topic, eliminating writer's block and tedious
            research. <br></br>
            <p>
              Using advanced natural language processing, our system tailors captivating content for your audience.
              <br></br>
              From personal blogs to professional websites, our AI companion streamlines your content creation process.
            </p>
          </h3>
        </div>
        <div className="section-right">
          <img className="section-img" src={pencil} alt="pencil"></img>
        </div>
      </div>

      {/* SECTION 2 */}
      <div className="section section-2">
        <div className="section-left">
          <img className="section-img" src={header} alt="sculpture"></img>
        </div>
        <div className="section-right">
          <h1>Effortless Publishing and Scheduling</h1>
          <h3>
            Embrace automation with our AI tool's seamless integration with WordPress, Ghost, and API.
            <p>
              Easily schedule article publishing in advance, ensuring a consistent flow of fresh content for your readers.
              <br></br>
              Our powerful publishing automation saves time and energy, allowing you to maintain an active online presence
              effortlessly.
            </p>
            Sit back and relax as our AI takes care of posting your articles on the scheduled dates.
          </h3>
        </div>
      </div>

      {/* SECTION 3 */}
      <div className="section section-subscribe">
        <div className="btn-content">
          <button onClick={() => openModal("Open register modal from section 3")} className="primary big">
            Get Started Free
          </button>
        </div>
      </div>

      {/* SECTION 4 */}
      <div className="section section-4">
        <div className="section-side-3">
          <div className="section-side-img-container">
            <img className="section-side-img" src={drawing} alt="drawing"></img>
          </div>
          <h2>Intelligent Keyword Research</h2>
          <h3>
            Uncover the most relevant and high-traffic keywords effortlessly with our AI-driven tool. Boost your SEO strategy and
            target the right audience, ensuring your content ranks higher on search engines.
          </h3>
        </div>
        <div className="section-side-3">
          <div className="section-side-img-container">
            <img className="section-side-img" src={painting} alt="painting"></img>
          </div>
          <h2>Data-Driven Content Optimization</h2>
          <h3>
            Let our AI analyze your content and provide data-driven recommendations for improvement. From readability to keyword
            density, our tool ensures your articles are perfectly optimized for maximum engagement and search visibility.
          </h3>
        </div>
        <div className="section-side-3">
          <div className="section-side-img-container">
            <img className="section-side-img" src={sculpting} alt="sculpting"></img>
          </div>
          <h2>Auto-Scheduling & Social Media Integration</h2>
          <h3>
            Take advantage of our automated publishing and seamless social media integration. Schedule articles in advance and
            share them across your social platforms with ease, expanding your reach and driving more traffic to your blog.
          </h3>
        </div>
      </div>

      {/* SECTION 5 */}
      <div className="section section-subscribe">
        <div className="btn-content">
          <button onClick={() => openModal("Open register modal from footer")} className="primary big">
            Get Started Free
          </button>
        </div>
      </div>

      {showModalRegister && <RegisterModal close={() => setShowModalRegister(false)}></RegisterModal>}

      <Footer></Footer>
    </>
  );
}
