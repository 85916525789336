// import useTracking from "../services/useTracking";

export default function Footer() {
  // const tracking = useTracking("Footer");

  return (
    <div className="footer">
      <p>© 2023 Create Blog AI All rights reserved.</p>
    </div>
  );
}
