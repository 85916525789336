import { Link } from "react-router-dom";
import hamburger from "../images/hamburger.svg";
import logo from "../images/logo.svg";
import useTracking from "../services/useTracking";
import { useState } from "react";
import RegisterModal from "./RegisterModal";

export default function Navbar({ transparent }) {
  const tracking = useTracking("Navbar");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const triggerMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className={"navbar" + (transparent ? " transparent" : "")}>
      <div className="navbar-left">
        <Link
          to="/"
          onClick={() => {
            tracking("Click on logo from Navbar");
          }}
        >
          <img className="navbar-logo" src={logo} alt="Logo"></img>
          <span className="navbar-logo-text">Create Blog AI</span>
        </Link>
      </div>

      <div className="navbar-center"></div>

      <div className="navbar-burger-content">
        <img onClick={() => triggerMenu()} className="navbar-burger" src={hamburger} alt="hamburger"></img>
      </div>

      <div className={"navbar-right" + (showMenu ? " open" : "")}>
        <div className="navbar-btn">
          <Link
            to="/blog"
            onClick={() => {
              tracking("Click on blog navbar");
            }}
          >
            Blog
          </Link>
        </div>
        <div className="navbar-btn">
          <a
            href={process.env.REACT_APP_APP_URL}
            onClick={() => {
              tracking("Click on login navbar");
            }}
          >
            Login
          </a>
        </div>
        <div className="navbar-btn main">
          <button
            className={transparent ? "" : "blue"}
            onClick={() => {
              tracking("Click on early access from Navbar");
              setShowModalRegister(true);
            }}
          >
            Get Started Free
          </button>
        </div>
      </div>
      {showModalRegister && <RegisterModal close={() => setShowModalRegister(false)}></RegisterModal>}
    </div>
  );
}
