import { useState } from "react";
import api from "../services/api";
import useTracking from "../services/useTracking";
import { GoogleLogin } from "@react-oauth/google";
import { useAuth } from "../context/useAuth";

var validate = require("react-email-validator");

export default function RegisterModal({ close }) {
  const tracking = useTracking("Modal");
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let auth = useAuth();

  const subscribe = async () => {
    let valid = validate.validate(email);
    if (!valid) {
      tracking("Email not valid");
      return setError("Email not valid");
    }
    if (!password) {
      tracking("Password not valid");
      return setError("Password not valid");
    }

    setError();
    let res = await api.register(email, password);

    if (res.status === 200) {
      auth.register(res.data.token, email);
    } else if (res.status === 400) {
      setError("Email already subscribed");
    } else {
      setError("Error");
    }
  };

  return (
    <>
      <div className="modal-backdrop">
        <div className="modal">
          <button className="close-btn" onClick={close}>
            X
          </button>

          <div className="modal-content">
            <h3>Create your account</h3>
            <input
              onKeyDown={(e) => e.keyCode === 13 && subscribe()}
              type="text"
              className="input"
              placeholder="Insert your email"
              onChange={(e) => setEmail(e.target.value)}
            ></input>

            <input
              onKeyDown={(e) => e.keyCode === 13 && subscribe()}
              type="password"
              className="input"
              placeholder="Insert your password"
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <button onClick={subscribe} className="primary big">
              Create Free Account
            </button>

            <h4>OR</h4>

            <GoogleLogin
              onSuccess={(credentialResponse) => {
                auth.successLoginGoogle(credentialResponse);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />

            {error && <div className="mt-20 error">{error}</div>}
            <a href={process.env.REACT_APP_APP_URL} className="mt-20 message">
              I already have an account
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
