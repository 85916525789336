import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 30000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  const login = (username, password) => {
    return api.post(`/api/adminUsers/login`, { username, password });
  };

  const getUser = () => {
    return api.get(`/api/adminUsers/getUser`, {});
  };

  const subscribeNewsletter = (email) => {
    return api.post(`/api/users/subscribeNewsletter`, { email });
  };

  const register = (email, password) => {
    return api.post(`/api/users/register`, { email, password });
  };

  const registerGoogle = (credential) => {
    return api.post(`/api/users/registerGoogle`, { credential });
  };

  const getBlog = (id) => {
    return api.get(`/api/blogPostPublic/${id}`);
  };

  const getBlogPosts = () => {
    return api.get(`/api/blogPostPublic`);
  };

  // return functions for interface
  return {
    setToken,
    login,
    getUser,
    register,
    subscribeNewsletter,
    getBlog,
    getBlogPosts,
    registerGoogle,
  };
};

// Creates a singleton
export default createApi();
